import React from 'react';

import { Helmet } from 'react-helmet';

import { StaticImage } from 'gatsby-plugin-image';

import {
    Navbar,
    Footer,
    Contact,
    ProjectStack,
    ProjectsCloud,
    projects,
    Header,
    Section,
} from '../components';

export interface ecomsaasProps {}

export const ecomsaas: React.FC<ecomsaasProps> = () => {
    return (
        <main className="">
            <Helmet>
                <title>Ecommerce SAAS - B. Jasulan</title>
            </Helmet>
            <Navbar />
            <Section>
                <Header align="left">Ecommerce SAAS</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        Mobile app that allows people create their own ecommerce
                        websites in minutes by using WYSIWYG builder. I decided
                        to build this application to help my freelance
                        customers.
                    </p>
                    <p className="mt-8">
                        The project was built from scratch using React Native,
                        Nest.js, PostgreSQL, and GraphQL. During the development
                        process I am learning a lot of new techniques and
                        skills.
                    </p>
                    <ProjectStack
                        type="Personal Project"
                        stack={[
                            'TypeScript',
                            'Nest.js (Node)',
                            'PostgreSQL',
                            'GraphQL',
                            'React Native',
                            'React Native Web',
                            'Redux',
                            'TailwindCSS',
                            'NX',
                        ]}
                    />
                </div>
                <div className="flex -mx-4">
                    <div className="w-1/2 md:w-1/3 px-4">
                        <StaticImage
                            className="inline-block rounded-lg shadow-xl"
                            alt="Google autosuggestion box"
                            src="../images/ecom-design-builder.png"
                        />
                    </div>
                    <div className="w-1/2 md:w-1/3 px-4">
                        <StaticImage
                            className="inline-block rounded-lg shadow-xl"
                            alt="Google autosuggestion box"
                            src="../images/ecom-add-product.png"
                        />
                    </div>
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Problems and Thought Process</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        To organize code, I structured it in a mono-repo. Using
                        React Native and React Native Web allowed me to share a
                        lot of code between the mobile and the web version of
                        the app.
                    </p>
                    <p className="mt-8">
                        The biggest challenge was implementing a secure
                        authorization method for users. After some
                        consideration, I decided to use JSON Webtokens alongside
                        with refresh tokens. To secure those tokens, I stored
                        them in the keychain storage which is available in iOS
                        and Android.
                    </p>
                    <p className="mt-8">
                        Another challenge was implementing WYSIWYG editor for
                        product descriptions. After some research, I decided to
                        implement it simply by putting an editor like Quill
                        inside of WebView.
                    </p>
                    <p className="mt-8">
                        This project is still work-in-progress so there will be
                        a lot of new challenges ahead which will teach me new
                        skills and techniques.
                    </p>
                </div>
                <div className="mt-16 flex items-center">
                    <div className="w-1/2 md:w-1/3 px-4">
                        <StaticImage
                            className="rounded-lg shadow-xl"
                            alt="DesignShuffle"
                            src="../images/ecom-product-description.png"
                        />
                    </div>
                    <div className="w-1/2 md:w-1/3 px-4">
                        <StaticImage
                            className="rounded-lg shadow-xl"
                            alt="DesignShuffle"
                            src="../images/ecom-images.png"
                        />
                    </div>
                </div>
            </Section>
            <Section
                header="Lessons Learned"
                className="pt-14 md:pt-16 pb-32 mt-3"
            >
                <div className="mx-auto max-w-4xl text-base leading-8">
                    <p>
                        Every project has its own challenges and teaches new
                        lessons. This project refreshed my memory on a lot of
                        technologies, including, React Native, Quill,
                        PostgreSQL, and many more.
                    </p>
                    <p className="mt-8"></p>
                </div>
            </Section>
            <div className="mb-16">
                <ProjectsCloud active={projects[0]} />
            </div>
            <Contact />
            <Footer />
        </main>
    );
};

export default ecomsaas;
